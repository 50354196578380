import { Box, Stack, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useAuth } from '../../auth/auth-hook';
import { getPostUrl } from '../../auth/auth-utils';
import {
  professionalPlanBenefits,
  standardPlanBenefits,
  studentsPlanBenefits,
} from './benefitsByPlan';
import { createStyles, makeStyles } from '@mui/styles';
import { Plan, PlanFrequency } from '../../../shared/types/generated';

type PlanProps = {
  plan: Plan;
  typePlan?: 'professional' | 'student' | 'standard';
  includePromo?: boolean;
};
//theme: Theme
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    bannerPro: {
      backgroundImage: 'url("/banner-pro-two.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    bannerStudent: {
      backgroundImage: 'url("/banner-student-two.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    bannerStandard: {
      backgroundImage: 'url("/banner-standard-two.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    backgroundPro: {
      background: '#DCAB00',
      color: 'white',
      textTransform: 'uppercase',
    },
    backgroundStudent: {
      background: '#4052ED',
      color: 'white',
      textTransform: 'uppercase',
    },
    backgroundStandard: {
      background: '#FF3C41',
      color: 'white',
      textTransform: 'uppercase',
    },
  }),
);
export const USPeso = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
});
export const PlanNewCard: React.FC<PlanProps> = ({
  plan,
  typePlan,
  includePromo = false,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  let benefits: (string | ReactNode)[] = [];
  const backgroundclasses =
    typePlan === 'professional'
      ? classes.backgroundPro
      : typePlan === 'student'
      ? classes.backgroundStudent
      : classes.backgroundStandard;

  switch (plan.price) {
    case '2000':
      benefits = professionalPlanBenefits;
      break;
    case '200':
      benefits = standardPlanBenefits;
      break;
    case '110':
      benefits = studentsPlanBenefits;
      break;
  }

  return (
    <Stack
      spacing={0}
      mb={2}
      width={'328px'}
      paddingBottom={'10px'}
      fontFamily={'acumin-pro'}
      borderRadius={'8px'}
      overflow={'hidden'}
      component={'div'}
      position={'relative'}
      style={{ boxShadow: '0px 3px 8px #00000029' }}>
      <Box
        height={'40px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className={backgroundclasses}>
        <Typography
          fontFamily={'acumin-pro'}
          fontSize={'26px'}
          fontWeight={'bold !important'}
          lineHeight={'20px'}
          textAlign={'center'}>
          {plan.title}
        </Typography>
      </Box>
      <Box
        height={'140px'}
        width={'100%'}
        className={
          typePlan === 'professional'
            ? classes.bannerPro
            : typePlan === 'student'
            ? classes.bannerStudent
            : classes.bannerStandard
        }
      />

      {plan.sub_title ? (
        <Typography
          fontFamily={'acumin-pro'}
          textAlign={'center'}
          lineHeight={'20px'}
          color={'#606060'}
          mt={'10px'}
          textTransform={'uppercase'}>
          {plan.sub_title}
        </Typography>
      ) : (
        <Box paddingBottom={'16px'}></Box>
      )}

      <Typography
        fontFamily={'acumin-pro'}
        mt={plan.frequency === PlanFrequency.Year ? '8px' : '24px'}
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'30px'}
        lineHeight={'30px'}>
        {USPeso.format(Number(plan?.price))} MXN{' '}
        <span style={{ fontSize: '20px', color: '#000000' }}>
          {plan.frequency === PlanFrequency.Year ? '/año' : '/mes'}
        </span>
      </Typography>
      <Box display={'flex'} fontFamily={'acumin-pro'} justifyContent={'center'}>
        <Box width={'240px'}>
          <Typography
            mt={'8px'}
            fontFamily={'acumin-pro'}
            fontSize={'14px'}
            textAlign={'center'}>
            El pago por el monto indicado se realizará cada{' '}
            {plan.frequency === PlanFrequency.Year ? 'año' : 'mes'}
          </Typography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        marginTop={1}
        justifyContent={'center'}
        style={{ backgroundColor: '#FFFC59' }}
        mb={2}>
        <Box
          fontFamily={'acumin-pro'}
          lineHeight={'22px'}
          textAlign={'center'}
          paddingY={2}
          width={'250px'}
          dangerouslySetInnerHTML={{
            __html: plan.helper_text as string,
          }}></Box>
      </Box>
      <Box>
        <Typography
          fontFamily={'acumin-pro'}
          textAlign={'center'}
          fontSize={'12px'}
          color={'#606060'}
          my={'5px'}>
          Aprovecha esta oferta hasta el 31 de octubre del 2024.
        </Typography>
      </Box>
      <Box display={'flex'} mt={'10px'} justifyContent={'center'}>
        <Box
          component={'div'}
          role="button"
          tabIndex={0}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          style={{ background: '#FF3C41', cursor: 'pointer' }}
          width={'160px'}
          borderRadius={'20px'}
          height={'35px'}
          onClick={() => {
            if (user)
              return (window.location.href = `/metodos-de-pago/${
                plan.id
              }?post_url=${getPostUrl()}`);
            else {
              const redirectUrl = `${window.location.origin}/metodos-de-pago/${
                plan.id
              }?post_url=${getPostUrl()}`;
              window.location.href = `${
                window.location.origin
              }/autenticacion?redirect_url=${encodeURIComponent(redirectUrl)}`;
            }
          }}>
          <Typography
            fontFamily={'acumin-pro'}
            letterSpacing={'1px'}
            fontSize={'20px'}
            color={'#ffffff'}
            fontWeight={'bold'}
            textAlign={'center'}>
            Suscribirme
          </Typography>
        </Box>
      </Box>
      <ul
        style={{
          paddingLeft: '45px',
          paddingRight: '10px',
          margin: '15px 0px',
          height: '210px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          ...(includePromo && { justifyContent: 'initial' }),
        }}>
        {benefits.map((item, index) => (
          <li
            key={'list' + index}
            style={{ lineHeight: '18px', fontSize: '13px' }}>
            {item}
          </li>
        ))}
      </ul>
      {includePromo && (
        <>
          <Box height={'35px'} />
          <Box
            position={'absolute'}
            left={-1}
            bottom={-1}
            height={'103px'}
            width={'331px'}>
            <img
              src={'/promo-plan-de-los-muertos.png'}
              alt={'Promoción plan de los muertos'}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </>
      )}
      {/**<Typography
       fontSize={'12px'}
       color={'#515151'}
       textAlign={'center'}
       pb={'10px'}
       >
       Promoción de verano hasta el 30 de septiembre de 2024.
       </Typography> */}
    </Stack>
  );
};
